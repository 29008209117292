import { useTranslation } from 'react-i18next';
const SecContent = () => {
  const { t } = useTranslation();
	return(
        <div className="col-12 col-md-7">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-between">
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.2s">
              <h5>{t('FormNavigation')}</h5>
              <a href="#home"><p>{t('FormIntroduce')}</p></a>
              <a href="#about"><p>{t('FormAboutWATCH')}</p></a>
              <a href="#services"><p>{t('FormMarketPlanning')}</p></a>
              <a href="#ico"><p>{t('FormTechnologyArchitecture')}</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.3s">
              <h5>{t('FormFeaturesOfWATCH')}</h5>
              <a href><p>{t('FormBusinessDevelopments')}</p></a>
              <a href><p>{t('FormOurTeam')}</p></a>
              <a href><p>{t('FormWhitePaper')}</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.4s">
              <h5>{t('ContactUs')}</h5>
              <p>{t('Email')}：Info@block-table.com</p>
            </div>
          </div>
        </div>
	)
}

export default SecContent