import SectionHeading from '../../../components/SectionHeading'
import TokenInfo from './TokenInfo'
import { useTranslation } from 'react-i18next';

const SecDistribution = ({img , data}) => {

  const { t } = useTranslation();

  return (
    <section className="token-distribution" style={{paddingBottom: '100px'}}>
        <div className="container">
            
            <SectionHeading
                title={t('WATCHToken')}
                text={t('WATCHTokenText')}
            />

            <div className="col-lg-6 col-md-6 col-sm-12">
                <h2 className="text-center mb-30">WATCH {t('TokenEconomyModel')}</h2>
                <div style={{marginTop:'150px'}} className="token-allocation">
                    <img src={img} className="center-block" alt="" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="token-info-wapper"></div>
                <h2 className="text-center mb-30">{t('MoreInformation')}</h2>
                {data && data.map((item , key) => (
                    <TokenInfo
                        key={key}
                        title={item.title}
                        textArr={item.textArr}
                        img={item.img}
                    />
                ))}
            </div>
        </div>
        
    </section>
  );
}

export default SecDistribution;