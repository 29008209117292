import { useTranslation } from 'react-i18next';
const TokenInfo = ({textArr ,title, img}) => {

  const { t } = useTranslation();

  return (
    <div className="token-info">
        <div className="info-wrapper">
            <div className="token-icon" style={{backgroundImage: `url(${img})`}}></div>
            <div className="token-descr">
              <>{t(title)} <br />
              {textArr && textArr.map((item , key) => (
                <>{t(item.text)}<br/></>
              ))}
        </>
            </div>
        </div>
    </div>
  );
}

export default TokenInfo;