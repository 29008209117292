import SectionHeading from '../../../components/SectionHeading'
import { useTranslation } from 'react-i18next';
const SecPartners = ({ data }) => {

    const { t } = useTranslation();

    return (
        <section className="partners">

            <SectionHeading
                title={t('OurPartners')}
            // text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.'
            />

            <div className="container">
                <div className="row">
                    {data && data.map((item, key) => (
                        <div className="col-lg-2 col-sm-6" data-aos="fade-up" key={key}>
                            <div className="partner-box">
                                <img src={item.img} alt="" className="center-bock" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default SecPartners;