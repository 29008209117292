import SectionHeading from '../../../components/SectionHeading'
import Content from './Content'
import { useTranslation } from 'react-i18next';

const SecOurServices = ({data}) => {

  const { t } = useTranslation();

  return (
    <section className="our_services_area section-padding-100-70 clearfix" id="services">
        <div className="container">
            
            <SectionHeading
                title={t('TechnologyArchitecture')}
                text={t('TechnologyArchitectureText')}
            />
                

            <div className="row">
                {data && data.map((item , key) => (
                    <Content
                        key={key}
                        img={item.img}
                        title={t(item.title)}
                        text={t(item.text)}
                    />
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecOurServices;