import { useEffect } from "react";
import './style/HomeDemo1.scss'

import {
  VerticalSocial,
  SingleCoolFact,
  service_single_content,
  timelineInfo,
  ServiceBlock,
  SocialListIco,
  FQAInfo,
  DocElementTitle,
  TokenText,
  TeamMember,
  PartnersData
} from '../../data/data-containers/data-HomeDemo1.js';

import {
  HomeDemo1About1,
  HomeDemo1Solution,
  HomeDemo1VideoBg4,
  HomeDemo1ImgPhone,
  HomeDemo1RingsBg,
  HomeDemo1Allocation
} from '../../utils/allImgs'

import { handelTitle } from '../../utils'
import Header from "../../layouts/Header"
import Footer from '../../layouts/FooterPages'

import SecWelcomeArea from './SecWelcomeArea'
import SecVerticalSocial from './SecVerticalSocial'
import SecTrust from './SecTrust'
import SecAboutUsClient from './SecAboutUsClient'
import SecAboutUs from './SecAboutUs'
import SecDemoVideo from './SecDemoVideo'
import SecOurServices from './SecOurServices'
import SecOurRoadmap from './SecOurRoadmap'
import SecOurFeatures from './SecOurFeatures'
import SecSubscribe from './SecSubscribe'
import SecFAQ_Timeline from './SecFAQ_Timeline'
import SecDistribution from './SecDistribution'
import SecTeam from './SecTeam'
import SecPartners from './SecPartners'

let HomeDemo1 = () => {

  useEffect(() => {
    handelTitle('Block Table')
  }, [])

  useEffect(() => {
    if (document.title === 'Block Table') {
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }
  }, [])

  return (
    <>
      
      <Header />
      <div className="HomeDemo1">
        <SecWelcomeArea />
        {/* <SecVerticalSocial data={VerticalSocial} />侧边栏  */}
        <SecTrust data={SingleCoolFact} />
        <SecAboutUsClient img={HomeDemo1About1} />
        <SecAboutUs img={HomeDemo1Solution} />
        <div className="clearfix" />
        <SecDemoVideo img={HomeDemo1VideoBg4} />
        <div className="clearfix" />
        <SecOurServices data={service_single_content} />
        <SecOurFeatures data={ServiceBlock} imgPhone={HomeDemo1ImgPhone} Rings={HomeDemo1RingsBg} />
        <SecOurRoadmap data={timelineInfo} />
        {/* <SecFAQ_Timeline FQAInfo={FQAInfo} DocElementTitle={DocElementTitle} /> */}
        <SecDistribution img={HomeDemo1Allocation} data={TokenText} />
        <SecSubscribe data={SocialListIco} />
        <SecTeam data={TeamMember} />
        <SecPartners data={PartnersData} />
      </div>
      <Footer />
    </>
  );
};

export default HomeDemo1