import './SectionHeading.css'
import { useTranslation } from 'react-i18next';

const SectionHeading = () => {

  const { t } = useTranslation();

  return (
    <div className="section-heading ">
      <h2 className="title-head-subscribe" data-aos="fade-up">{t('SecSubscribeTitle')}</h2>
      <p className="text-head-subscribe" data-aos="fade-up">{t('SecSubscribeTitle1')}<br />{t('SecSubscribeTitle2')}</p>
    </div>
  );
}

export default SectionHeading;