import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from './locales/en';
import zhCN from './locales/zhCn';

const resources = {
    en: en,
    zhCn: zhCN
  };

i18n
.use(initReactI18next)
.init({
    resources,
    lng: "zhCn",
    interpolation: {
        escapeValue: false
    },
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
})

export default i18n;