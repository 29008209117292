import { useTranslation } from 'react-i18next';
const Span = () => <span></span>

const SecWhoWeContant = () => {

  const { t } = useTranslation();

  return (
    <div className="col-12 col-lg-6">
      <div className="who-we-contant">
        <div className="dream-dots" data-aos="fade-up">
          {Array(7).fill().map((key) => (
            <Span key={key} />
          ))}
        </div>
        <h4 data-aos="fade-up">{t('HealthMonitoring')}</h4>
        <p data-aos="fade-up">{t('HealthMonitoringText')}</p>
        <h4 data-aos="fade-up">{t('LongBatteryLife')}</h4>
        <p data-aos="fade-up">{t('LongBatteryLifeText')}</p>
        <a className="btn dream-btn mt-30" href="#">{t('ContactUs')}</a>
      </div>
    </div>
  );
}

export default SecWhoWeContant;