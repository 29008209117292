import SectionHeading from '../../../components/SectionHeading'
import Timeline from './Timeline'
import { useTranslation } from 'react-i18next';
const SecOurRoadmap = ({data}) => {

  const { t } = useTranslation();

  return (
    <section className="roadmap" style={{paddingBottom:100}} id="ico">
        <SectionHeading
            title={t('MarketingStrategy')}
            text={t('MarketingStrategyText')}
        />
        <div className="container">
          <div className="row">
            <div className="timeline-split">
              <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="timeline section-box-margin">
                      {data && data.map((item , key) => (
                          <Timeline
                            key={key}
                            timelineClass={item.timelineClass}
                            title={t(item.title)}
                            date_from={item.date_from}
                            date_to={item.date_to}
                            text={t(item.text)}
                          />
                        ))}
                      <div className="circle"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default SecOurRoadmap;