import { useTranslation } from 'react-i18next';
const Span = () => <span></span>

const SecWhoWeContant = () => {

  const { t } = useTranslation();

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots">
                {Array(7).fill().map((key) => (
                		<Span key={key}/>
                	))}
            </div>
            <h4 data-aos="fade-up">{t('FashionDesign')}</h4>
            <p data-aos="fade-up">{t('FashionDesignText')}</p>
            <h4 data-aos="fade-up">{t('SmartFunctions')}</h4>
            <p data-aos="fade-up">{t('SmartFunctionsText')}</p>
            {/* <a data-aos="fade-up" className="btn dream-btn mt-30" href="#">联系我们</a> */}
        </div>
    </div>
  );
}

export default SecWhoWeContant;