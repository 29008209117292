import SectionHeading from './SectionHeading'
import SecGroup from './SecGroup'
import {subscribeImg} from '../../../utils/allImgs'
import SecSocialList from './SecSocialList'
import { useTranslation } from 'react-i18next';

const SecSubscribe = ({data}) => {

  const { t } = useTranslation();

  return (
    <section className="container " style={{paddingBottom: '100px'}} id="start">
        <div className="subscribe">
            <div className="row">
                <div className="col-sm-12">
                    <div className="subscribe-wrapper">
                        <div class="col-lg-4 col-md-4 col-xs-12 text-center">
                            <img src={subscribeImg} alt=""/>
                        </div>
                        <div className="col-lg-8 col-md-8 col-xs-12 text-left">
                            <SectionHeading />
                            <div className="service-text">
                            <p style={{color:"white"}}>{t('SecSubscribeText')}</p>
                                {/* <SecGroup /> */}
                                {/* <SecSocialList data={data} /> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>  
    </section>
  );
}

export default SecSubscribe;