import { useEffect } from 'react'

import { Logo } from '../../data/data-layout/data-Header.js';

import { Addshrink, addActiveClass, OpenMenu, moveSmooth } from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

import { useTranslation } from 'react-i18next';

const Header = () => {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  useEffect(() => {
    Addshrink()
  }, [])

  useEffect(() => {
    OpenMenu()
  }, [])

  useEffect(() => {
    moveSmooth()
  }, [])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="#"><img src={Logo} alt="logo" /></a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li><a onClick={moveSmooth} href="#home">{t('FrontPage')}</a></li>
                    <li><a onClick={moveSmooth} href="#about">{t('OurFeatures')}</a></li>
                    <li><a onClick={moveSmooth} href="#services">{t('TechnologyArchitecture')}</a></li>
                    <li><a onClick={moveSmooth} href="#ico">{t('MarketingStrategy')}</a></li>
                    <li><a onClick={moveSmooth} href="#team">{t('TeamIntroduction')}</a></li>
                    <li><a onClick={moveSmooth} href="#contact">{t('ContactUs')}</a></li>
                  </ul>
                  {/* <a href="#" className="btn login-btn ml-50">登入</a> */}
                </div>

              </div>
              {/* <div>
                  <p style={{ color: "red" }}>{t('welcome_message')}</p>
                  <button onClick={() => changeLanguage('en')}>English</button>
                  <button onClick={() => changeLanguage('zhCn')}>中文</button>
                </div> */}

              <div class="dropdown">
                <button class="btn dropdown-toggle language-btn" type="button" data-toggle="dropdown" aria-expanded="false">
                  {t('Language')}
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item language-item" onClick={() => changeLanguage('en')}>English</a>
                  <a class="dropdown-item language-item" onClick={() => changeLanguage('zhCn')}>中文</a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;