const en = {
  translation: {
    // Header
    "Language": "English",
    "FrontPage": "Front page",
    "OurFeatures": "Our features",
    "TechnologyArchitecture": "Technology Architecture",
    "MarketingStrategy": "Marketing strategy",
    "TeamIntroduction": "Team introduction",
    "ContactUs": "Contact us",

    // SecWelcomeArea
    "SmartWatche": "Smart watch",
    "SWText1": "Block Table smart watches combine fashionable design and smart technology to provide modern people with a smarter and more convenient lifestyle. By blending advanced smart features and exquisite watch craftsmanship, Block Table will redefine expectations for watches and become an indispensable accessory in daily life.",
    "SWText2": "And we are excited to announce that its innovative smartwatch will be publicly available on the official website on December 1, 2024, priced at US$299.",
    "EnquireNow": "Enquire Now",
    "AboutUs": "About Us",

    // SecTrust
    "STText1": "The watch market has always been a dynamic and competitive field. As technology advances and people's demand for smart products increases, the share of smart watches in the market continues to grow. However, existing smartwatches often fall short in design, quality and user experience, which presents us with a huge opportunity.",
    "STText2": "The Block Table smartwatch uses advanced wearable technology, equipped with a high-definition touch screen, smart operating system and rich applications. Its precise sensors and intelligent algorithms can monitor users' health data, activities and sleep quality in real time, and provide users with personalized health management suggestions.",

    // SecAboutUsClient SecWhoWeContant
    "FashionDesign": "Fashion design",
    "FashionDesignText": "Made of selected high-quality materials, it has a stylish and simple appearance design, suitable for various occasions.",
    "SmartFunctions": "Smart functions",
    "SmartFunctionsText": "Integrate practical functions such as intelligent voice assistant, message reminder, map navigation, etc. to improve user life efficiency.",

    // SecAboutUs SecWhoWeContant
    "HealthMonitoring": "health monitoring",
    "HealthMonitoringText": "Real-time monitoring of heart rate, steps, sleep and other health data helps users scientifically manage their health.",
    "LongBatteryLife": "Long battery life",
    "LongBatteryLifeText": "It adopts high-efficiency energy-saving technology and long-life design to meet users' all-day use needs.",

    // SecDemoVideo
    "CheckOutOurProducts": "Check out our products",

    // SecOurServices
    "TechnologyArchitectureText": "The Block Table smart watch uses advanced wearable technology, combining high-performance hardware and smart software to provide users with comprehensive smart functions and a high-quality user experience.",
    "SecOurServicesTitle1": "HD touch screen",
    "SecOurServicesText1": "Block Table is equipped with a high-definition, responsive touch screen, and users can implement various functions through finger taps, slides, or gesture operations.",
    "SecOurServicesTitle2": "Health sensor",
    "SecOurServicesText2": "It has a variety of built-in sensors, including heart rate sensors, motion sensors, sleep monitors, etc., which can monitor users' health data in real time and provide accurate health analysis and suggestions.",
    "SecOurServicesTitle3": "Smart chip",
    "SecOurServicesText3": "It uses a high-performance smart chip to support multi-tasking and efficient energy consumption management to ensure stable operation and long battery life of the watch.",
    "SecOurServicesTitle4": "Connection module",
    "SecOurServicesText4": "Integrated Bluetooth and Wi-Fi modules support connection and data synchronization with smartphones, cloud services and other devices to achieve more intelligent functions and interconnection.",
    "SecOurServicesTitle5": "Intelligent operating system",
    "SecOurServicesText5": "The Block Table smart watch adopts a self-developed intelligent operating system, which has the characteristics of high stability, friendly interface and fast response speed, providing users with a smooth operating experience.",
    "SecOurServicesTitle6": "App",
    "SecOurServicesText6": "It has a rich set of built-in applications, including clock, calendar, weather, health management, message reminders, map navigation and other functions to meet users' various daily needs.",
    "SecOurServicesTitle7": "Voice assistant",
    "SecOurServicesText7": "Integrated intelligent voice assistant supports voice recognition and voice control. Users can operate the watch through voice commands to achieve a smarter and more convenient use experience.",
    "SecOurServicesTitle8": "Data analysis",
    "SecOurServicesText8": "Built-in intelligent algorithms and data analysis modules can conduct in-depth analysis of users' health data and activities, and provide users with personalized health management suggestions.",

    // SecOurFeatures
    "BusinessModel": "Business model",
    "BusinessModelText": "The business model of Block Table smart watches mainly includes product sales and service charging. It achieves profitability and commercial success through multi-channel sales and customized health management services.",
    "ProductSales": "Product sales",
    "ProductSalesText": "We sell Block Table smart watch products through online sales and offline retail channels, providing high-quality smart watch products to our users. Online sales channels include its own official website, e-commerce platforms and social media platforms, providing users with convenient purchasing channels. Offline retail channels include specialty stores, department stores, physical stores, etc., covering major cities and regions, providing users with a comprehensive purchasing experience.",
    "ServiceCharges": "Service charges",
    "ServiceChargesText": "In addition to product sales, we also provide customized health management services to provide users with personalized health management solutions and services. Users can choose different health management service packages based on personal needs and preferences to enjoy more comprehensive health management services. Services include health data monitoring, health consultation, exercise guidance, dietary advice, etc., helping users scientifically manage their health and improve their quality of life.",
    "BusinessModelAdvantages":"Business model advantages",
    "BusinessModelAdvantagesText":"Multi-channel sales: Through the combination of online sales and offline retail channels, it covers a wider user group and maximizes sales revenue. Combination of products and services: Combining product sales with customized services to provide users with more complete and personalized solutions and enhance user stickiness and satisfaction. Income diversification: not only relying on product sales to obtain income, but also charging service fees through customized services to achieve income diversification and reduce operating risks. User orientation: Oriented by user needs, continuously optimize products and services, improve user experience and satisfaction, and establish long-term and stable user relationships.",

    // SecOurRoadmap
    "MarketingStrategyText":"Block Table smart watches will adopt a variety of marketing strategies, including integrated marketing through online and offline channels, as well as cooperative promotion with partners, to enhance brand awareness and market share.",
    "SecOurRoadmapTitle1":"Social media marketing",
    "SecOurRoadmapText1":"Through social media platforms, such as Facebook, Instagram, Twitter, etc., publish product information, promotions and user cases, interact with users, build brand image and user trust, and attract the attention and participation of potential users.",
    "SecOurRoadmapTitle2":"Online advertising promotion",
    "SecOurRoadmapText2":"Utilize online advertising channels such as search engine advertising, social media advertising, and website banner advertising to accurately locate target user groups, deliver personalized advertising content, increase brand exposure and click-through rates, and increase website traffic and sales conversion rate.",
    "SecOurRoadmapTitle3":"Offline physical store promotion",
    "SecOurRoadmapText3":"Cooperate with offline physical stores such as major department stores and fashion boutiques to open brand counters or display cabinets to display product samples and promotional materials to attract customers' attention and purchases, and increase brand awareness and sales.",
    "SecOurRoadmapTitle4":"Brand cooperation promotion",
    "SecOurRoadmapText4":"Cooperate with fashion magazines, healthy living websites, fitness clubs and other brands to promote, release cooperation content, hold joint activities, sponsor brand activities, etc., expand brand influence and user groups, and increase brand exposure and user conversion rate.",
    "SecOurRoadmapTitle5":"User word-of-mouth marketing",
    "SecOurRoadmapText5":"Through user word-of-mouth and social sharing, we can enhance brand reputation and credibility, encourage users to publish product reviews and usage experiences, share product experiences and recommendations, and attract more potential users' attention and purchases.",
    "SecOurRoadmapTitle6":"limited time promotion",
    "SecOurRoadmapText6":"Regularly hold limited-time promotions, such as discounts, discounts, gifts, etc., to attract users' attention and purchases, increase sales and user satisfaction, and enhance brand loyalty and user retention.",

    // SecDistribution
    "WATCHToken":"WATCH Token",
    "WATCHTokenText":"In addition, Block Table has partnered with the famous cryptocurrency platform CoinblockMax to develop a new cryptocurrency called WATCH. This integration aims to revolutionize the intersection of health, technology and digital assets, creating a groundbreaking decentralized project that promotes the health and wealth of users.",
    "TokenEconomyModel":"Token economy model",
    "MoreInformation":"More information",
    // TokenText
    "TokenTitle1":"Token utility",
    "TextArr1Text1":"1. Health rewards: Users earn WATCH tokens by achieving set health goals (such as step count, heart rate zone, and sleep quality).",
    "TextArr1Text2":"2. Feature access: Some advanced smartwatch features require WATCH tokens to unlock.",
    "TextArr1Text3":"3. Community management: WATCH token holders can participate in voting for smart watch function updates and health plans.",

    "TokenTitle2":"Token issuance",
    "TextArr2Text1":"1. Initial issuance: WATCH tokens will have a fixed initial supply.",
    "TextArr2Text2":"2. Mining mechanism: Users can 'mine' WATCH tokens by frequently using the health monitoring function of smart watches.",
    "TextArr2Text3":"3. Deflation mechanism and reward design: Transaction fees will be used to fund the liquidity pool and burn tokens, thereby effectively controlling the supply and providing ongoing dividend rewards to encourage long-term holding.",

    "TokenTitle3":"Value capture",
    "TextArr3Text1":"1. Transaction fee: When making transactions within the smart watch app store, a certain percentage of WATCH token fees will be charged.",
    "TextArr3Text2":"2. Partnership: Cooperate with health product providers to allow users to use WATCH tokens to purchase health products or services.",

    "TokenTitle4":"Token circulation",
    "TextArr4Text1":"1. Liquidity incentives: A liquidity pool will be set up to allow users to earn interest by depositing WATCH tokens.",
    "TextArr4Text2":"2. Token buyback and burning: Regular buyback and burning of tokens using app store revenue will reduce the circulating supply and increase scarcity.",

    // SecSubscribe
    "SecSubscribeText":"The WATCH token will only be listed in the first round on CoinblockMax, and subsequently plans to apply for listing and trading on well-known exchanges such as Coinbase, Binance, Kraken, etc. Users please stay tuned for the arrival of the digital age.",
    "SecSubscribeTitle":"Security and Compliance",
    "SecSubscribeTitle1":"1. Smart contract audit: Ensure the security of token smart contracts and prevent vulnerabilities and attacks.",
    "SecSubscribeTitle2":"2. Legal compliance: Comply with relevant laws and regulations to ensure the legality of token issuance and transactions.",

    // SecTeam
    "SecTeamText":"The Block Table smartwatch team consists of a group of talented people with rich experience and professional skills. We are committed to creating high-quality and high-performance smartwatch products to provide users with a smarter, healthier and more convenient life experience.",
    "SecTeamText1":"CEO",
    "SecTeamContent1":"The founder team is composed of senior technical experts and business leaders, with rich entrepreneurial experience and successful cases. They are committed to combining advanced technology with fashionable design to create an industry-leading smart watch product that leads the trend of fashionable technology.",
    "SecTeamText2":"R&D Team",
    "SecTeamContent2":"Our R&D team consists of a group of engineers and designers with solid technical skills and innovative spirit. They are committed to developing high-performance, high-quality smart watch products. They constantly pursue technological innovation and product optimization to ensure product stability and reliability.",
    "SecTeamText3":"Marketing Team",
    "SecTeamContent3":"Our marketing team consists of a group of passionate and creative marketing experts who are well versed in market dynamics and user needs, develop and implement effective marketing strategies, and increase brand awareness and market share. They continue to explore and innovate and promote their products to a wider user group.",
    "SecTeamText4":"Customer Service Team",
    "SecTeamContent4":"Our customer service team is composed of a group of enthusiastic and thoughtful customer service personnel who provide users with professional and efficient pre-sales and after-sales services around the clock, answer user questions and solve problems, and ensure user satisfaction and user experience.",
    "SecTeamText5":"Operations Team",
    "SecTeamContent5":"Our operations team consists of a group of operations personnel with rich experience and professional skills, who are responsible for product manufacturing, supply chain management, logistics and distribution, and other operations to ensure the smooth progress of product production and sales.",
    "SecTeamText6":"Quality Team",
    "SecTeamContent6":"Our quality team is composed of a group of rigorous and responsible quality management personnel. From raw material procurement to product production, from product testing to after-sales service, they always put product quality first and ensure that products meet international standards and user expectations.",
    "SecTeamFoot":"Our team adheres to the core values ​​of 'innovation, quality, and service', constantly pursues technological innovation and product optimization, provides users with a smarter, healthier, and more convenient life experience, and becomes a leader and benchmark enterprise in the smart watch industry.",

    // SecPartners
    "OurPartners":"Our Partners",

    // Footer
    "ContactUsText":"If you have any questions or need further information about our services, please feel free to contact us. Our customer service team will be happy to assist you.",
    // Form
    "FormName": "Name",
    "FormEmail": "Email",
    "FormTitle": "Title",
    "FormContent": "Content",
    "FormSubmit": "Submit",
    // SecIco
    "SecIcoText":"Block Table Smartwatch is a high-end watch that combines stylish design and smart technology, aiming to provide a smarter and more convenient lifestyle for modern people. By combining advanced smart features and exquisite watch craftsmanship, Block Table will redefine what people expect from a watch and become an indispensable accessory in their daily lives.",
    // SecContent
    "FormNavigation":"Navigation",
    "FormIntroduce":"Introduce",
    "FormAboutWATCH":"About WATCH",
    "FormMarketPlanning":"Market planning",
    "FormTechnologyArchitecture":"Technology Architecture",
    "FormFeaturesOfWATCH":"Features of WATCH",
    "FormBusinessDevelopments":"Business developments",
    "FormOurTeam":"Our team",
    "FormWhitePaper":"White paper",

    "Email":"Email",

  }
};

export default en;