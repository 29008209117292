// import { HomeDemo1money } from '../../../../utils/allImgs'

import { useTranslation } from 'react-i18next';

function SecWelcomeContent({ img }) {

    const { t } = useTranslation();

    return (
        <div className="welcome-content">
            <div className="promo-section">
                {/* <div className="integration-link">
                    <span className="integration-icon">
                        <img src={img} width="24" height="24" alt="" />
                    </span>
                    <span className="integration-text">您的财富，我们的使命!</span>
                </div> */}
            </div>
            <h1>Block Table {t('SmartWatche')}</h1>
            <p>
                {t('SWText1')}
            </p>
            <p>
                {t('SWText2')}
            </p>
            <div className="dream-btn-group">
                <a href="#" className="btn dream-btn mr-3">{t('EnquireNow')}</a>
                <a href="#" className="btn dream-btn">{t('AboutUs')}</a>
            </div>
        </div>
    )
}

export default SecWelcomeContent