import SectionHeading from '../../../components/SectionHeading'
import SecSingleCoolFact from './SecSingleCoolFact'
import { useTranslation } from 'react-i18next';


const SecTrust = ({ data }) => {

  const { t } = useTranslation();

  return (
    <section className="trust-section section-padding-100 d-flex justify-content-center flex-wrap" id="about">

      <SectionHeading
        title={t('OurFeatures')}
        text={t('STText1')}
      />

      <p style={{ textAlign: 'center', width: "80%" }}>{t('STText2')}</p>
      {/* <div className="container">
            <div className="row">
                <SecSingleCoolFact data={data}/>
            </div>
        </div> */}
    </section>
  );
}

export default SecTrust;