const zhCN = {
  translation: {
    // Header
    "Language": "中文",
    "FrontPage": "首页",
    "OurFeatures": "我们的特点",
    "TechnologyArchitecture": "技术架构",
    "MarketingStrategy": "营销策略",
    "TeamIntroduction": "团队介绍",
    "ContactUs": "联系我们",

    // SecWelcomeArea
    "SmartWatche": "智能手表",
    "SWText1": "Block Table 智能手表结合了时尚设计和智能科技，旨在为现代人提供更智能、更便捷的生活方式。通过融合先进的智能功能和精湛的手表工艺，Block Table将重新定义手表的期待，成为日常生活中的不可或缺的配件。",
    "SWText2": "并且我们兴奋地宣布，其创新型智能手表将于2024年12月1日在官方网站上公开发售，售价299美元。",
    "EnquireNow": "立刻咨询",
    "AboutUs": "关于我们",

    // SecTrust
    "STText1": "手表市场一直是一个充满活力和竞争激烈的领域。随着科技的进步和人们对智能产品的需求增加，智能手表在市场上的份额不断增长。然而，现有的智能手表往往在设计、品质和用户体验上存在不足，这为我们提供了一个巨大的机会。",
    "STText2": "Block Table 智能手表采用先进的可穿戴技术，配备了高清触摸屏、智能操作系统和丰富的应用程序。其精准的传感器和智能算法可以实时监测用户的健康数据、活动情况和睡眠质量，为用户提供个性化的健康管理建议。",

    // SecAboutUsClient SecWhoWeContant
    "FashionDesign": "时尚设计",
    "FashionDesignText": "精选优质材料打造，时尚简约的外观设计，适合各种场合佩戴。",
    "SmartFunctions": "智能功能",
    "SmartFunctionsText": "集成智能语音助手、消息提醒、地图导航等实用功能，提升用户生活效率。",

    // SecAboutUs SecWhoWeContant
    "HealthMonitoring": "健康监测",
    "HealthMonitoringText": "实时监测心率、步数、睡眠等健康数据，帮助用户科学管理健康。",
    "LongBatteryLife": "长续航",
    "LongBatteryLifeText": "采用高效节能技术，长续航设计，满足用户全天使用需求。",

    // SecDemoVideo
    "CheckOutOurProducts": "查看我们的产品",

    // SecOurServices
    "TechnologyArchitectureText": "Block Table 智能手表采用先进的可穿戴技术，结合了高性能硬件和智能软件，为用户提供全面的智能功能和优质的使用体验。",
    "SecOurServicesTitle1": "高清触摸屏",
    "SecOurServicesText1": "Block Table 搭载一块高清、响应灵敏的触摸屏，用户可以通过手指轻触、滑动或者手势操作来实现各种功能。",
    "SecOurServicesTitle2": "健康传感器",
    "SecOurServicesText2": "内置多种传感器，包括心率传感器、运动传感器、睡眠监测器等，能够实时监测用户的健康数据并提供精准的健康分析和建议。",
    "SecOurServicesTitle3": "智能芯片",
    "SecOurServicesText3": "采用高性能的智能芯片，支持多任务处理和高效能耗管理，保证手表的稳定运行和长续航时间。",
    "SecOurServicesTitle4": "连接模块",
    "SecOurServicesText4": "集成蓝牙和Wi-Fi模块，支持与智能手机、云服务等设备的连接和数据同步，实现更多的智能功能和互联互通。",
    "SecOurServicesTitle5": "智能操作系统",
    "SecOurServicesText5": "Block Table 智能手表采用自主开发的智能操作系统，具有稳定性高、界面友好、响应速度快等特点，为用户提供流畅的操作体验。",
    "SecOurServicesTitle6": "应用程序",
    "SecOurServicesText6": "内置丰富的应用程序，包括时钟、日历、天气、健康管理、消息提醒、地图导航等功能，满足用户各种日常需求。",
    "SecOurServicesTitle7": "语音助手",
    "SecOurServicesText7": "集成智能语音助手，支持语音识别和语音控制，用户可以通过语音指令来操作手表，实现更智能、更便捷的使用体验。",
    "SecOurServicesTitle8": "数据分析",
    "SecOurServicesText8": "内置智能算法和数据分析模块，能够对用户的健康数据和活动情况进行深度分析，为用户提供个性化的健康管理建议。",

    // SecOurFeatures
    "BusinessModel": "商业模式",
    "BusinessModelText": "Block Table 智能手表的商业模式主要包括产品销售和服务收费两个方面，通过多渠道的销售和定制化的健康管理服务，实现盈利和商业成功。",
    "ProductSales": "产品销售",
    "ProductSalesText": "我们通过在线销售和线下零售渠道销售Block Table 智能手表产品，为广大用户提供高品质的智能手表产品。在线销售渠道包括自有官方网站、电商平台和社交媒体平台等，为用户提供便捷的购买渠道。线下零售渠道包括专卖店、百货商场、实体店等，覆盖各大城市和地区，为用户提供全方位的购买体验。",
    "ServiceCharges": "服务收费",
    "ServiceChargesText": "除了产品销售外，我们还提供定制化的健康管理服务，为用户提供个性化的健康管理方案和服务。用户可以根据个人需求和偏好，选择不同的健康管理服务套餐，享受更全面的健康管理服务。服务内容包括健康数据监测、健康咨询、运动指导、饮食建议等，帮助用户科学管理健康，提高生活质量。",
    "BusinessModelAdvantages": "商业模式优势",
    "BusinessModelAdvantagesText": "多渠道销售：通过在线销售和线下零售渠道的结合，覆盖更广泛的用户群体，实现销售收入的最大化。产品与服务结合：产品销售与定制化服务相结合，为用户提供更完整、更个性化的解决方案，增强用户黏性和满意度。收入多元化：不仅依靠产品销售获得收入，还通过定制化服务收取服务费用，实现收入多元化，降低经营风险。用户导向：以用户需求为导向，不断优化产品和服务，提升用户体验和满意度，建立长期稳定的用户关系。",

    // SecOurRoadmap
    "MarketingStrategyText": "Block Table 智能手表将采取多种营销策略，包括线上和线下渠道的整合营销，以及与合作伙伴的合作推广，提升品牌知名度和市场份额。",
    "SecOurRoadmapTitle1": "社交媒体营销",
    "SecOurRoadmapText1": "通过社交媒体平台，如Facebook、Instagram、Twitter等，发布产品信息、促销活动和用户案例，与用户互动交流，建立品牌形象和用户信任，吸引潜在用户的关注和参与。",
    "SecOurRoadmapTitle2": "网络广告推广",
    "SecOurRoadmapText2": "利用搜索引擎广告、社交媒体广告、网站横幅广告等在线广告渠道，精准定位目标用户群体，投放个性化的广告内容，提高品牌曝光度和点击率，增加网站流量和销售转化率。",
    "SecOurRoadmapTitle3": "线下实体店推广",
    "SecOurRoadmapText3": "与各大百货商场、时尚精品店等线下实体店合作，开设品牌专柜或展示柜，展示产品样品和宣传资料，吸引顾客关注和购买，提升品牌知名度和销售额。",
    "SecOurRoadmapTitle4": "品牌合作推广",
    "SecOurRoadmapText4": "与时尚杂志、健康生活网站、健身俱乐部等品牌合作推广，发布合作内容、举办联合活动、赞助品牌活动等，扩大品牌影响力和用户群体，增加品牌曝光度和用户转化率。",
    "SecOurRoadmapTitle5": "用户口碑营销",
    "SecOurRoadmapText5": "通过用户口碑和社交分享，提升品牌口碑和信誉度，鼓励用户发布产品评价和使用心得，分享产品体验和推荐，吸引更多潜在用户的关注和购买。",
    "SecOurRoadmapTitle6": "限时促销活动",
    "SecOurRoadmapText6": "定期举办限时促销活动，如折扣优惠、满减活动、赠品赠送等，吸引用户关注和购买，增加销售额和用户满意度，提升品牌忠诚度和用户留存率。",

    // SecDistribution
    "WATCHToken": "WATCH 代币",
    "WATCHTokenText": "此外，Block Table还与著名的加密货币平台CoinblockMax合作开发了一种名为WATCH的新型加密货币。这一整合旨在彻底改变健康、技术和数字资产的交叉点，创建一个开创性的去中心化项目，促进用户健康和财富的发展。",
    "TokenEconomyModel": "代币经济模型",
    "MoreInformation": "更多信息",
    // TokenText
    "TokenTitle1": "代币效用",
    "TextArr1Text1": "1.健康奖励： 用户通过实现设定的健康目标（如步数、心率区域和睡眠质量）获得 WATCH 代币。",
    "TextArr1Text2": "2.功能访问： 某些高级智能手表功能需要 WATCH 代币才能解锁。",
    "TextArr1Text3": "3.社区管理： WATCH 代币持有者可参与智能手表功能更新和健康计划的投票。",

    "TokenTitle2": "代币发行",
    "TextArr2Text1": "1.首次发行： WATCH 代币将设定一个固定的初始供应量。",
    "TextArr2Text2": "2.挖矿机制： 用户可以通过频繁使用智能手表的健康监测功能来 '挖掘' WATCH代币。",
    "TextArr2Text3": "3.通缩机制和奖励设计： 交易费用将用于为流动性池提供资金并进行代币燃烧，从而有效控制供应量，并提供持续的分红奖励以激励长期持有。",

    "TokenTitle3": "价值获取",
    "TextArr3Text1": "1.交易费： 在智能手表应用商店内进行交易时，将收取一定比例的 WATCH 代币费用。",
    "TextArr3Text2": "2.合作伙伴关系： 与健康产品提供商合作，允许用户使用 WATCH 代币购买健康产品或服务。",

    "TokenTitle4": "代币流通",
    "TextArr4Text1": "1.流动性激励： 将设立一个流动资金池，允许用户通过存入 WATCH 代币赚取利息。",
    "TextArr4Text2": "2.代币回购和烧毁：使用应用程序商店收入定期回购和烧毁代币，将减少流通供应，增加稀缺性。",

    // SecSubscribe
    "SecSubscribeText": "WATCH代币仅在CoinblockMax进行首轮上市，随后并计划在Coinbase,Binance，Kraken等知名交易所申请上市交易，请用户敬请期待数字时代的到来。",
    "SecSubscribeTitle": "安全与合规",
    "SecSubscribeTitle1": "1.智能合约审计： 确保代币智能合约的安全性，防止漏洞和攻击。",
    "SecSubscribeTitle2": "2.法律合规性： 遵守相关法律法规，确保代币发行和交易的合法性。",

    // SecTeam
    "SecTeamText": "Block Table 智能手表团队由一群具有丰富经验和专业技能的人才组成，我们致力于打造高品质、高性能的智能手表产品，为用户提供更智能、更健康、更便捷的生活体验。",
    "SecTeamText1": "创始人",
    "SecTeamContent1": "创始人团队由资深的技术专家和商业领袖组成，拥有丰富的创业经验和成功案例。他们致力于将先进的科技与时尚设计相结合，打造出一款领先行业的智能手表产品，引领时尚科技潮流。",
    "SecTeamText2": "研发团队",
    "SecTeamContent2": "我们的研发团队由一批具有扎实技术功底和创新精神的工程师和设计师组成，他们致力于开发出高性能、高品质的智能手表产品。他们不断追求技术创新和产品优化，确保产品的稳定性和可靠性。",
    "SecTeamText3": "市场团队",
    "SecTeamContent3": "我们的市场团队由一群富有激情和创意的市场营销专家组成，他们精通市场动态和用户需求，制定并实施有效的营销策略，提升品牌知名度和市场份额。他们不断探索和创新，将产品推广到更广泛的用户群体中。",
    "SecTeamText4": "客服团队",
    "SecTeamContent4": "我们的客服团队由一批热情周到的客服人员组成，他们全天候为用户提供专业、高效的售前售后服务，解答用户疑问和解决问题，确保用户满意度和用户体验。",
    "SecTeamText5": "运营团队",
    "SecTeamContent5": "我们的运营团队由一群具有丰富经验和专业技能的运营人员组成，他们负责产品生产制造、供应链管理、物流配送等运营工作，确保产品生产和销售的顺利进行。",
    "SecTeamText6": "品质团队",
    "SecTeamContent6": "我们的品质团队由一批严谨负责的品质管理人员组成，他们从原材料采购到产品生产，从产品测试到售后服务，始终把产品质量放在首位，确保产品符合国际标准和用户期望。",
    "SecTeamFoot": "我们的团队秉承着“创新、品质、服务”的核心价值观，不断追求技术创新和产品优化，为用户提供更智能、更健康、更便捷的生活体验，成为智能手表行业的领先者和标杆企业。",

    // SecPartners
    "OurPartners": "我们的合作伙伴",

    // Footer
    "ContactUsText": "如果您有任何问题或需要进一步了解我们的服务，请随时与我们联系。我们的客服团队将竭诚为您服务。",
    // Form
    "FormName": "姓名",
    "FormEmail": "邮箱",
    "FormTitle": "标题",
    "FormContent": "内容",
    "FormSubmit": "发送消息",
    // SecIco
    "SecIcoText": "Block Table 智能手表是一款结合了时尚设计和智能科技的高端手表，旨在为现代人提供更智能、更便捷的生活方式。通过融合先进的智能功能和精湛的手表工艺，Block Table 将重新定义人们对手表的期待，成为他们日常生活中的不可或缺的配件。",

    // SecContent
    "FormNavigation": "导航",
    "FormIntroduce": "介绍",
    "FormAboutWATCH": "关于WATCH",
    "FormMarketPlanning": "市场规划",
    "FormTechnologyArchitecture": "技术架构",
    "FormFeaturesOfWATCH": "WATCH的特点",
    "FormBusinessDevelopments": "商业发展",
    "FormOurTeam": "我们的团队",
    "FormWhitePaper": "白皮书",

    "Email": "邮箱",
  }
};

export default zhCN;