import SectionHeading from '../../../components/SectionHeading'
import Form from './Form'
import { useTranslation } from 'react-i18next';

const SecContact = () => {
  const { t } = useTranslation();
    return (
        <div className="contact_us_area" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <SectionHeading
                      title={t('ContactUs')}
                      text={t('ContactUsText')}
                />
              </div>
            </div>
            <Form />
          </div>
        </div>
    );
}

export default SecContact